<template>
	<section class="page">
		<div class="segment-1">
			<div class="artist-image">
				<img :src="main.poster" alt="" />
			</div>
			<h1>
				{{ main.name }}
			</h1>
			<div class="tags">
				<div v-for="(t, index) in main.tags" :key="index" class="tag">
					{{ t }}
				</div>
			</div>
			<div class="description">
				{{ main.description }}
			</div>
			<div class="addable-hover-info">
				<div
					class="date-container"
					:class="{ tba: main.date.str === 'TBA' }"
				>
					<div class="date">
						{{ main.date.str }}
					</div>
					<div class="weekday">
						{{ main.date.minWeek }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="organizers"
			:style="{
				opacity: main.partners.length > 0 ? 1 : 0,
			}"
		>
			<span
				:style="{
					opacity: main.partnersLabel.length > 0 ? 1 : 0,
				}"
				>{{ main.partnersLabel }}:</span
			>
			<div class="sub-imgs-mass">
				<div class="orgs">
					<img
						v-for="(o, k) in main.partners"
						:key="k"
						:src="o"
						alt=""
					/>
				</div>
				<div class="socials">
					<img
						v-for="(o, k) in main.socials"
						:key="k"
						:src="o"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="artists">
			<h2>{{ $t("artists") }}</h2>
			<slider-component :mass="artists">
				<template v-slot="content">
					<div class="c-img-container">
						<img :src="content.content.image" alt="" />
					</div>
					<div class="content-info">
						<h3>
							{{ content.content.name }}
						</h3>
						<div class="c-description">
							{{ content.content.description }}
						</div>
					</div>
				</template>
			</slider-component>
		</div>
		<div class="media-files" id="photos">
			<h2>{{ $t("photosPast") }}</h2>
			<gallery :video="mediaBlock.video" :images="mediaBlock.images" />
		</div>
	</section>
</template>

<script>
import SliderComponent from "../components/SliderComponent.vue";
import { http } from "../components/services/httpService";
import parseDateMixin from "./parseDateMixin";
import Gallery from "../components/Gallery.vue";
export default {
	name: "PastEvent",
	mixins: [parseDateMixin],
	components: { SliderComponent, Gallery },
	props: {
		isMobile: {
			default: false,
		},
	},
	data() {
		return {
			main: {
				id: 0,
				url: "",
				name: "",
				description: "",
				poster: "",
				date: "",
				minPrice: 0,
				minAge: 0,
				tags: [],
				partnersLabel: "",
				partners: [],
				socials: {
					vk: "",
					instagram: "",
					facebook: "",
				},
			},
			artists: [],
			mediaBlock: {
				video: "",
				images: [],
			},
			media: {
				x: 0,
				pos: 0,
				container: null,
			},
		};
	},
	methods: {
		getEventInfo() {
			http.get(
				`/api/events/${this.$route.params.name}?lang=${this.$i18n.locale}`
			).then((response) => {
				if (response.status < 400) {
					document.documentElement.scrollTop = 0;
					this.saveAllData(response.data);
				}
			});
		},
		saveAllData(data) {
			this.main = {
				...data.baseBlock,
				date: this.parseDate(data.baseBlock.date),
			};
			this.artists = data.artistsBlock.artists;
			this.ticketsBlock = data.ticketsBlock;
			this.mediaBlock = data.mediaBlock;
			this.categories = data.categoriesBlock.categories;
			this.similarEvents = data.similarBlock.events;
			document.title = this.main.name;
		},
	},
	computed: {
		parseYoutubeUrl() {
			const url = this.mediaBlock.video.split("=");
			let src = url[1];
			if (url.length > 2) {
				src = src.split("&")[0];
				src += "?start=" + url[2];
			}
			return src;
		},
	},
	beforeRouteEnter(to, from, next) {
		const locale = localStorage.getItem("locale");
		http.get(
			`/api/events/${to.params.name}?lang=${locale ? locale : "ru-RU"}`
		).then((response) => {
			if (response.status < 400) {
				next((vm) => {
					vm.$el.scrollTop = 0;
					vm.saveAllData(response.data);
				});
			}
		});
	},
	watch: {
		"$i18n.locale": function() {
			this.getEventInfo();
		},
	},
	mounted() {
		setTimeout(() => {
			if (this.$route.params.scroll) {
				this.$el.querySelector("#photos").scrollIntoView();
				// document.documentElement.scrollTop = 200000;
			} else {
				document.documentElement.scrollTop = 0;
			}
		}, 0);
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/event.scss";
</style>
