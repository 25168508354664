<template>
	<section>
		<h1>{{ $t("contacts") }}</h1>
		<div class="main-info">
			<span>{{ contacts.address }}</span>
			<a :href="contacts.map.url">{{ $t("openInMaps") }}</a>
		</div>
		<div class="sub-info">
			<div class="info-href">
				<div>{{ $t("rent") }}:</div>
				<a :href="'https://' + contacts.rentSite">
					{{ contacts.rentSite }}
				</a>
			</div>
			<div class="info-href">
				<div>{{ $t("partnersFor") }}:</div>
				<a :href="'mailto:' + contacts.partnersEmail">
					{{ contacts.partnersEmail }}
				</a>
			</div>
			<div class="info-href">
				<a :href="'https://www.t.me/' + contacts.socials.telegram">
					{{ $t("vip") }}
					<br />
					<span> @{{ contacts.socials.telegram }} </span>
				</a>
			</div>
		</div>
		<!-- :src="contacts.map.image" -->
		<img class="map" src="static/img/map.jpg" alt="" />
	</section>
</template>

<script>
import { http } from "../components/services/httpService";
export default {
	data() {
		return {
			contacts: {
				city: "",
				address: "",
				email: "",
				phone: "",
				workTime: "10:00-19:00",
				partnersEmail: "",
				rentSite: "",
				map: {
					image: "/static/img/map.svg",
					url: "",
				},
				socials: {
					vk: "",
					instagram: "",
					facebook: "",
					telegram: "",
				},
			},
		};
	},
	methods: {
		getContacts() {
			http.get(`/api/contacts?lang=${this.$i18n.locale}`).then(
				(response) => {
					if (response.status < 400) {
						this.contacts = response.data;
						const t = this.contacts.socials.telegram.split("@")[1];
						this.contacts.socials.telegram = t;
					}
				}
			);
		},
	},
	watch: {
		"$i18n.locale": function() {
			this.getContacts();
		},
		"$route.name"(newVal) {
			if (newVal === "contacts") {
				document.title = this.$t("contacts");
			}
		},
	},
	mounted() {
		this.getContacts();
		document.title = this.$t("contacts");
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	margin-top: ptr(30px);
	h1 {
		font-size: ptr(140px);
		font-weight: 100;
		margin: 0 0 0 ptr(-11px);
		transform: translateY(ptr(-15px));
	}
}
.main-info {
	margin-top: ptr(35px);
	span {
		font-size: ptr(40px);
		font-weight: 100;
		text-transform: uppercase;
	}
	a {
		margin-top: ptr(27px);
		border-radius: ptr(100px);
		background: white;
		color: black;
		font-size: ptr(14px);
		display: flex;
		align-items: center;
		justify-content: center;
		width: ptr(256px);
		height: ptr(55px);
		cursor: pointer;
	}
}
.sub-info {
	position: absolute;
	bottom: ptr(60px);
	font-weight: 100;
	font-size: ptr(20px);
}
.info-href {
	margin-top: ptr(40px);
	a {
		display: inline-block;
		margin-top: ptr(5px);
	}
}
.map {
	position: absolute;
	right: ptr(50px);
	bottom: ptr(50px);
	width: ptr(845px);
	height: ptr(845px);
}
@media (max-width: 1024px) {
	section {
		margin: 0;
		padding: ptr(20px) ptr(10px) 0;
		position: relative;
		height: ptr(700px);
		h1 {
			font-size: ptr(46px);
			margin: 0 0 0 ptr(-2px);
			transform: unset;
		}
	}
	.main-info {
		margin-top: ptr(10px);
		span {
			font-size: ptr(19px);
		}
		a {
			margin-top: ptr(30px);
			font-size: ptr(14px);
			width: ptr(301px);
			height: ptr(53px);
		}
	}
	.sub-info {
		position: relative;
		bottom: 0;
		display: grid;
		gap: ptr(20px);
		margin-top: ptr(42px);
	}
	.info-href {
		margin: 0;
		font-size: ptr(15px);
	}
	.map {
		width: ptr(298px);
		height: ptr(298px);
		right: ptr(12px);
		bottom: 0;
	}
}
</style>
