<template>
  <main id="app">
    <router-link to="/" class="mobile-logo">
      <img
          src="/static/icons/temple.svg"
          alt="templeofdeer иконка"
          v-if="this.$route.path !== '/ghost'"
      />
      <img
          src="/static/icons/logo-placebo25.svg"
          alt="templeofdeer иконка"
          v-if="this.$route.path === '/ghost'"
      />
    </router-link>
    <side-menu></side-menu>
    <keep-alive :exclude="['EventPage', 'PastEvent']">
      <router-view :isMobile="isMobile"/>
    </keep-alive>
    <div class="mobile-footer" :class="`${$route.name}`">
      <div class="logo">
        <img src="/static/icons/temple.svg" alt="templeofdeer иконка"/>
      </div>
      <div class="socials">
        <!--				<a :href="footerInfo.socials.instagram" class="s-container">-->
        <!--					<img src="/static/icons/instagram.svg" alt="" />-->
        <!--				</a>-->
        <!--				<a :href="footerInfo.socials.facebook" class="s-container">-->
        <!--					<img src="/static/icons/facebook.svg" alt="" />-->
        <!--				</a>-->
        <a :href="footerInfo.socials.vk" class="s-container">
          <img src="/static/icons/vk.svg" alt=""/>
        </a>
        <a
            :href="'https://www.t.me/' + footerInfo.socials.telegram"
            class="s-container tlg"
        >
          <img src="/static/icons/telegram.svg" alt=""/>
        </a>
      </div>
      <!--      Убрал email-->
      <!--      <a :href="`mailto:${footerInfo.email}`" class="email">-->
      <!--        {{ footerInfo.email }}-->
      <!--      </a>-->
      <div class="copyright">Temple of Deer © 2021</div>
    </div>
  </main>
</template>

<script>
import {http} from "./components/services/httpService";
import SideMenu from "./components/SideMenu.vue";

export default {
  components: {SideMenu},
  data() {
    return {
      isMobile: false,
      footerInfo: {
        address: "",
        email: "tod@placebo25.com",
        socials: {
          vk: "",
          instagram: "",
          facebook: "",
          telegram: "",
        },
      },
    };
  },
  methods: {
    getSideInfo() {
      http
          .get(`/api/contacts/sidebar?lang=${this.$i18n.locale}`)
          .then((response) => {
            if (response.status < 400) {
              this.footerInfo = response.data;
              const t = response.data.socials.telegram.split("@")[1];
              this.footerInfo.socials.telegram = t;
            }
          });
    },
  },
  mounted() {
    this.getSideInfo();
    this.isMobile = window.innerWidth < 1024 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 1024 ? true : false;
    });
  },
};
</script>

<style lang="scss">
@import "./components/styles/config.scss";

*::selection {
  background: $sub-color;
  color: white;
}

body {
  margin: 0;
  height: 100%;
  width: 100vw;
  color: white;
  overflow-x: hidden;
  font-family: $main-font;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

button {
  font-family: $main-font;
  border: none;
}

a {
  text-decoration: none;
  color: white;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: ptr(10px) 0 0 ptr(263px);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fixed-img {
  position: absolute;
  z-index: -1;
  width: ptr(2864px);
  // height: ptr();
}

.event-container {
  display: grid;
  gap: ptr(31px);
  min-height: ptr(123px);
  position: relative;
  padding-top: ptr(60px);
  grid-template-columns: ptr(160px) auto repeat(2, max-content);

  .event-info {
    margin-top: ptr(-2px);
  }

  .event-age {
    position: relative;
    top: ptr(27px);
  }

  .buy-tickets {
    position: relative;
    top: ptr(7px);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ptr(1px);
    background: rgba(255, 255, 255, 0.21);
  }
}

.event-data {
  transition: $trs;

  .number {
    font-size: ptr(39px);
    font-weight: 400;
  }

  font-size: ptr(16px);

  span {
    text-transform: capitalize;
  }
}

.tags {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: ptr(5px);
  top: ptr(29px);
  position: absolute;
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  border-radius: ptr(100px);
  background: rgba(255, 255, 255, 0.2);
  padding: 1px 9px 0;
  font-size: 12px;
  opacity: 0.7;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;

  @media(max-width: 900px) {
    padding: ptr(1px) ptr(9px) 0;
    font-size: ptr(12px);
    height: ptr(18px);
  }
}

.artist-name {
  font-size: ptr(49px);
  transition: $trs;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: ptr(-2px);
}

.event-description {
  font-size: ptr(17.9px);
  margin-top: ptr(5px);
  line-height: ptr(25px);
  font-weight: 300;
}

.event-age {
  font-size: ptr(18px);
  font-weight: 300;
}

.addable-hover-info {
  margin-top: ptr(27px);
  display: flex;
  align-items: center;
  padding-bottom: ptr(50px);
}

.date-container {
  position: relative;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  justify-items: center;
  gap: ptr(30px);
  width: 288px;
  height: 57px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: ptr(100px);
  box-sizing: border-box;
  padding-right: ptr(20px);
  font-size: 18px;
  font-weight: 400;
  margin-right: ptr(17px);

  @media(max-width: 900px) {
    width: ptr(288px);
    height: ptr(57px);
    font-size: ptr(20px);
  }

  div {
    transform: translateY(ptr(1px));
  }

  &::after {
    content: "";
    position: absolute;
    height: ptr(40px);
    width: ptr(1px);
    right: ptr(72px);
    background: rgba(255, 255, 255, 0.3);
  }
}

.tba {
  grid-template-columns: auto;
  padding: 0;
  align-items: center;

  .weekday {
    display: none;
  }

  &::after {
    content: unset;
  }
}

.weekday {
  text-transform: capitalize;
}

@keyframes hover {
  from {
    background: #b61c3a;
  }
  to {
    background: #ce2144;
  }
}

.buy-tickets {
  background: white;
  width: 256px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ptr(100px);
  border: none;
  font-family: $main-font;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: black;
  transition: $trs;

  @media(max-width: 900px) {
    width: ptr(256px);
    height: ptr(55px);
    font-size: ptr(14px);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    text-transform: uppercase;

    img {
      filter: invert(0);
    }

    &:hover {
      color: white;
      background: rgba(255, 255, 255, 0.35);
    }
  }

  &:hover {
    background: #b61c3a;
    color: white;

    img {
      filter: invert(0);
    }

    // animation: hover .8s ease-in-out infinite alternate;
  }

  p {
    margin: 0;
  }

  img {
    transition: $trs;
    filter: invert(1);
    width: ptr(13px);
    margin-right: ptr(14.5px);
  }
}

.main-t {
  background: $sub-color;
  color: white;

  img {
    filter: invert(0);
  }

  &:hover {
    background: #b61c3a;
  }
}

.age {
  font-size: 18px;
  margin-left: ptr(20px);
  @media(max-width: 900px) {
    font-size: ptr(18px);
  }
}

.chosen {
  opacity: 1 !important;
}

.m-br {
  display: none;
}

.hovered {
  .main-group svg {
    opacity: 1;
    transform: translateX(0px);
  }
}

.socials-in {
  margin-top: ptr(20px);
  display: grid;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
  gap: ptr(10px);

  a {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    height: ptr(30px);
    width: ptr(30px);
    align-items: center;
    justify-content: center;
    transition: $trs;

    &:hover {
      background: $sub-color;
    }
  }

  img {
    height: ptr(11px);
    max-width: ptr(13px);
    object-fit: contain;
    transition: $trs;
    filter: invert(1);
  }

  .site {
    background: unset;
    border: 1px solid rgb(117, 117, 117);
    border-radius: ptr(100px);
    cursor: pointer;
    height: ptr(40px);
    font-size: ptr(14px);
    width: ptr(200px);

    &:hover {
      background: $sub-color;
      border: 1px solid $sub-color;
    }
  }
}

@media (max-width: 1024px) {
  .m-br {
    display: inline;
  }
  #app {
    padding: ptr(88px) 0 0;
    margin: 0;
    position: relative;
    overflow: hidden;
  }
  .buy-tickets {
    background: white;
    width: ptr(202px);
    height: ptr(42px);
    font-size: ptr(12px);
    grid-column: 2;

    img {
      width: ptr(12px);
    }
  }
  .main-t {
    background: $sub-color;
    color: white;

    img {
      filter: invert(0);
    }
  }
  .socials-in {
    .site {
      height: ptr(35px);
      width: ptr(170px);

      &:hover {
        background: $sub-color;
        border: 1px solid $sub-color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "./components/styles/config.scss";

.mobile-footer,
.mobile-logo {
  display: none;
}

@media (max-width: 1024px) {
  .mobile-footer {
    margin: ptr(50px) 0;
    display: grid;
    gap: ptr(22px);

    &::before {
      content: "";
      background: rgba(255, 255, 255, 0.26);
      position: relative;
      height: ptr(1px);
      left: ptr(10px);
      width: calc(100% - 20rem / 16);
    }
  }
  .mobile-logo {
    display: block;
    position: absolute;
    top: ptr(12px);
    left: ptr(12px);
    z-index: 3;

    &::after {
      content: "";
      justify-self: center;
      transition: $trs;
      position: absolute;
      left: ptr(-12px);
      bottom: ptr(-10px);
      width: ptr(320px);
      background: #ffffff;
      height: ptr(1px);
      transition: $trs;
    }

    img {
      width: ptr(39px);
    }
  }
  .Event,
  .Poster {
    &::before {
      content: unset;
    }
  }
  .logo {
    padding: ptr(22px) 0;
    display: flex;
    justify-content: center;

    img {
      width: ptr(65px);
    }
  }
  .socials {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: ptr(19px);
    justify-content: center;
  }
  .s-container {
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    width: ptr(32px);
    height: ptr(32px);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: ptr(10px);
      filter: invert(1);
    }

    &:nth-child(0) {
      img {
        width: ptr(6.3px);
      }
    }

    &:nth-child(1) {
      img {
        width: ptr(13px);
      }
    }
  }
  .tlg {
    img {
      width: ptr(13px);
      transform: translateX(ptr(-1px));
      filter: none;
    }
  }
  .email {
    font-weight: 100;
    font-size: ptr(18px);
    justify-self: center;
    padding: ptr(2px) ptr(9px);
    border-radius: ptr(100px);
    border: ptr(1px) solid rgba(255, 255, 255, 0.5);
  }
  .copyright {
    justify-self: center;
    color: #c4c4c4;
    font-weight: 100;
    font-size: ptr(10px);
  }
}
</style>
