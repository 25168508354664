<template >
  <div v-if="show" class="dress-code">
    <h2 class="dress-code__title">Дресс-код фестиваля Temple of Ghost</h2>
    <div class="dress-code__text">
      <p class="dress-code__item">Итак, главное правило, лежащее в основе создания вашего образа – вы собираетесь на
        Halloween. </p>
      <p class="dress-code__item">Простор фантазии тут может быть поистине безграничен, и мы дадим лишь легкие подсказки
        и обозначим несколько
        уровней сложности:</p>
      <ul class="dress-code__item">
        <li><b>Уровень 1 – base.</b> Подразумевает одежду черного, красного и белого цветов. Тут подойдут и различного
          кроя
          платья, и наш любимый black tie, и стильные образы техно-кобр, и готические луки разных мастей. Избегайте лишь
          спортивную одежду!
        </li>
        <li><b>Уровень 2</b> подразумевает дополнение образа гримом / аксессуарами в тематике Halloween.</li>
        <li><b>Уровень 3</b> подразумевает выход за рамки базовых цветов, сложный сценический грим, cosplay. Подходит
          тем, кто
          собирается создать что-то поистине сложное и неординарное.
        </li>
      </ul>
      <p class="dress-code__item">Референсы для вдохновения в нашей подборке. Не ограничивайте свою фантазию –
        погрузитесь в атмосферу Temple of
        Ghost!</p>
    </div>
    <div class="dress-code__reference">
      <!--      <img :src="images[0]" alt="">-->
      <gallery :images="images"/>
    </div>
  </div>
</template>

<script>
import image_1 from '../assets/dressCode/photo_2023-10-26_15-28-35.jpg';
import image_2 from '../assets/dressCode/photo_2023-10-26_15-28-37.jpg';
import image_3 from '../assets/dressCode/photo_2023-10-26_15-28-38.jpg';
import image_4 from '../assets/dressCode/photo_2023-10-26_15-28-39.jpg';
import image_5 from '../assets/dressCode/photo_2023-10-26_15-28-40.jpg';
import image_6 from '../assets/dressCode/photo_2023-10-26_15-28-41.jpg';
import image_7 from '../assets/dressCode/photo_2023-10-26_15-28-43.jpg';
import image_8 from '../assets/dressCode/photo_2023-10-26_15-28-44.jpg';
import image_9 from '../assets/dressCode/photo_2023-10-26_15-28-45.jpg';
import image_10 from '../assets/dressCode/photo_2023-10-30_14-59-27.jpg';


import Gallery from "@/components/Gallery.vue";

export default {
  props: {
    show:{
      default: false
    }
  },
  components: {Gallery},
  data() {
    return {
      images: [image_10, image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9]
    }
  }
}
</script>

<style lang="scss">
.dress-code {
  @import "../components/styles/config.scss";
  margin-top: ptr(290px);
  @media(max-width: 1024px) {
    margin-top: ptr(90px);
  }

  &__title {
    font-size: ptr(74px);
    margin: 0 0 ptr(46px);
    font-weight: 100;
    @media(max-width: 1024px) {
      font-size: ptr(33px);
      margin: 0 0 ptr(23px);
    }
  }

  &__text {
    max-width: ptr(790px);
    width: 100%;
  }

  &__item {
    li {
      list-style: disc;
      list-style-position: inside;

      &:not(:last-child) {
        margin-bottom: ptr(20px);
      }
    }
  }
}
</style>
